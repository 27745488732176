<script setup lang="ts">
import { ref, computed } from "vue";

const props = withDefaults(
  defineProps<{
    type?:
      | "primary"
      | "secondary"
      | "ternary"
      | "tertiary-secondary"
      | "tertiary-primary"
      | "primary-light"
      | "secondary-light";
    to?: string;
    fontSize?: "base" | "lg" | "xl" | "";
    rounded?: "small" | "medium" | "large";
    height?: "" | "small" | "medium" | "large";
    padding?: "absence" | "normal" | "";
    outlined?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    type: "primary",
    to: "",
    fontSize: "base",
    rounded: "small",
    height: "small",
    padding: "",
    outlined: false,
    loading: false,
    disabled: false,
  }
);

defineEmits(["click"]);

const stroke = ref("");

const cssClasses = computed(() => {
  let classes = [];

  // switch (props.padding) {
  //   case "normal":
  //     classes.push("px-14 py-3");
  //     break;
  //   case "absence":
  //     classes.push("px-4");
  //     break;
  //   default:
  //     classes.push("");
  //     break;
  // }

  switch (props.fontSize) {
    case "base":
      classes.push("text-base");
      break;
    case "lg":
      classes.push("text-lg");
      break;
    case "xl":
      classes.push("text-xl");
      break;
    default:
      classes.push("text-sm");
      break;
  }

  switch (props.rounded) {
    case "small":
      classes.push("rounded-lg");
      break;
    case "medium":
      classes.push("rounded-xl");
      break;
    case "large":
      classes.push("rounded-full");
      break;
    default:
      classes.push("rounded-lg");
      break;
  }

  switch (props.height) {
    case "small":
      classes.push("h-10");
      break;
    case "medium":
      classes.push("h-12");
      break;
    case "large":
      classes.push("h-14");
      break;
    default:
      classes.push("h-10");
      break;
  }
  if (props.outlined) {
    // classes.push("border");
    // if (props.type === "primary") {
    //   classes.push("border-primary-100 text-white");
    // }
    // if (props.type === "secondary") {
    //   classes.push("border-secondary text-white");
    // }
  } else {
    if (props.type === "primary") {
      classes.push("bg-primary-300 text-white font-medium");
      stroke.value = "#fff";
    }
    if (props.type === "secondary") {
      classes.push("bg-secondary-300 text-white font-medium");
      stroke.value = "#fff";
    }
    if (props.type === "tertiary-secondary") {
      classes.push("text-black hover:text-secondary-300  font-lato");
    }
    if (props.type === "primary-light") {
      classes.push("text-primary-300 bg-white border-2 border-primary-300 font-lato");
    }
    if (props.type === "secondary-light") {
      classes.push("text-secondary-300 bg-white border-2 border-secondary-300 font-lato");
    }
  }
  if (props.disabled && props.type === "primary") {
    classes.push("bg-opacity-40 bg-primary-200 text-white cursor-not-allowed");
  } else if (props.disabled) {
    classes.push(
      "bg-secondary-300 bg-opacity-50 text-white cursor-not-allowed"
    );
  } else if (props.type === "primary") {
    classes.push("cursor-pointer hover:bg-primary-400");
  } else if (props.type === "secondary") {
    classes.push("cursor-pointer hover:bg-secondary-400");
  } else if (props.type === "tertiary-secondary") {
    classes.push(
      "hover:text-secondary-300 focused:text-secondary-400 font-lato"
    );
  } 
  else if (props.type === "primary-light") {
    classes.push("hover:bg-primary-300 hover:bg-opacity-10");
  }
  else if (props.type === "secondary-light") {
    classes.push("hover:bg-secondary-300 hover:bg-opacity-10");
  }
  return classes.join(" ");
});
</script>

<template>
  <nuxt-link
    v-if="to"
    :to="to"
    class="px-4 rounded hover:cursor-pointer font-sf-pro text-center flex relative z-0"
    :class="cssClasses"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span
      class="m-auto pb-px z-10"
      :class="[!loading ? 'opacity-100' : 'opacity-0']"
    >
      <slot />
    </span>
    <span
      v-if="loading"
      class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-10"
    >
      <p-icon-preloader :color="stroke"></p-icon-preloader>
    </span>
  </nuxt-link>
  <button
    v-else
    class="rounded font-sf-pro text-center flex relative"
    :class="cssClasses"
    :disabled="disabled"
    @click="!disabled ? $emit('click') : ''"
  >
    <span class="m-auto z-10" :class="[!loading ? 'opacity-100' : 'opacity-0']">
      <slot />
    </span>
    <span
      v-if="loading"
      class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-10"
    >
      <p-icon-preloader :color="stroke"></p-icon-preloader>
    </span>
  </button>
</template>
